import React, { useEffect } from "react";
import Helmet from "react-helmet";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function Privacy() {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <HomepageLayout>
            <Helmet data={JSONData}>
                <title>Privacy Policy</title>
                <link 
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap" 
                    rel="stylesheet" 
                />
                            <style type="text/css">{`
   footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;
    position: relative;
    color: #333;
    padding: 0;
    width: 100%;
    margin:0;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding: 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }

    `}
    </style>
            </Helmet>
            <header style={{ background: "#f0f0f0", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/carbonate-logo.png" alt="Main Logo" style={{ width: "180px" }}/>
            </header>
            <div
                name="termly-embed"
                data-id="24b80033-9965-4163-83ae-9b745d07fa39"
                data-type="iframe"
            ></div>
            <footer>
                <RawFooter />
            </footer>
        </HomepageLayout>
    );
}
